@import url(../../assets/styles/_global.scss);

.header {
  background-color: #eeeded;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  gap: 10px;
  padding-bottom: 15px;

  svg {
    vertical-align: middle;
  }
}

.editProductAction {
  margin-inline-start: auto;
  width: calc(100% - 250px);

  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  svg {
    vertical-align: middle;
  }
  .routes {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #202223;
    span {
      cursor: pointer;
    }
  }
}

.content {
  margin-inline-start: auto;
  width: calc(100% - 310px);
  margin-inline-end: 1rem;

  .body-rtl & {
    margin-inline-end: 2.3rem;
  }
}
