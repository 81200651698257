@import url(../../assets/styles/_global.scss);

.navbarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 20px 15px;
  color: #344767;
  background-color: white;
  margin-inline-start: 250px;
  overflow: hidden;

  & > div:first-of-type {
    display: flex;
    align-items: center;
    gap: 10px;
    div {
      display: flex;
      align-self: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid #17171742;
      width: 30px;
      padding: 4px 4px;
    }
  }
  .pendingStatus {
    background-color: #ffd79d;
    border-radius: 30px;
    font-size: 13px;
    padding: 5px 14px;
  }
  .approvedStatus {
    background-color: #aee9d1;
    border-radius: 30px;
    font-size: 13px;
    padding: 5px 14px;
  }
  .acceptedStatus {
    background-color: #16268d;
    color: white;
    border-radius: 30px;
    font-size: 13px;
    padding: 5px 14px;
  }
  .cancelledStatus {
    background-color: #e91f63;
    color: white;
    border-radius: 30px;
    font-size: 13px;
    padding: 5px 14px;
  }
  & > div:nth-of-type(2) {
    display: flex;
    gap: 10px;
  }
  svg {
    vertical-align: middle;
    cursor: pointer;
  }
  .profile {
    & > div:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      cursor: pointer;
      & > div:first-of-type {
        border-radius: 50%;
        background-color: #ffc96b;
        color: white;
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        color: #3d2800;
      }
      & > div:nth-of-type(2) {
        color: #202223;
      }
    }
  }

  .dropdown-content {
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    position: absolute;
    top: 85px;
    right: 20px;
    background-color: #344767;

    box-shadow: 0px 8px 16px 0px rgba(193, 143, 143, 0.2);
    z-index: 5;
    padding: 12px 24px;
  }

  .dropdown-content button {
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 12px 24px;
    cursor: pointer;
    background: #344767;
    border: 1px solid #babfc3;
    color: #f8f9fa;
    svg {
      vertical-align: middle;
    }
  }

  .dropdown-content a:hover {
    background-color: #ddd;
  }

  .show {
    display: block;
  }
}
