@import url(../../../../assets/styles/_global.scss);

.viewProductContainer {
  .viewProductAction {
    width: calc(100% - 250px);
    margin: 0px 0 30px;
    margin-inline-start: auto;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;

    svg {
      vertical-align: middle;
    }

    .routes {
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #202223;

      span {
        cursor: pointer;
      }
    }

    .editButton {
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 5px 14px 5px 12px;
        background: #ffffff;
        border: 1px solid #babfc3;
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #202223;
        cursor: pointer;
      }
    }
  }

  .content {
    width: calc(100% - 310px);
    margin: 0 15px 30px;
    margin-inline-start: auto;

    .container {
      padding: 10px 20px;
      background-color: white;
    }

    .header {
      background-color: #eeeded;
      display: flex;
      align-items: center;
      padding: 14px;
      border-radius: 4px;
      gap: 10px;

      svg {
        vertical-align: middle;
      }
    }

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
    }

    .data {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 3rem;
    }

    .productImages {
      display: flex;
      gap: 10px;
      align-items: flex-start;
      line-height: 3rem;
      font-weight: 500;

      & > div:first-of-type {
        img {
          width: 200px;
          height: 200px;
          object-fit: contain;
          border: 1px solid #17171742;
          border-radius: 2px;
        }
      }

      & > div:nth-of-type(2) {
        div {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 10px;
        }

        img {
          width: 80px;
          height: 80px;
          object-fit: contain;
          border: 1px solid #17171742;
          border-radius: 2px;
        }
      }
    }

    .productInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    hr {
      border-color: #e1e3e5;
      border-style: solid;
      border-width: 1px;
      margin: 20px 0;
    }

    & > div:nth-of-type(2) {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      gap: 20px;

      & > div:nth-of-type(1) {
        width: 100%;
      }

      & > div:nth-of-type(2) {
        width: 100%;

        .container {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          min-height: 65%;
        }
      }
    }
  }

  .tagContainer {
    padding: 4px 8px;
    background: #e4e5e7;
    border-radius: 4px;
    color: black;
    height: fit-content;

    span {
      width: fit-content;
    }
  }

  .varientsContainer {
    height: fit-content;
    margin-top: 30px;

    .tableContainer {
      width: 100%;
      min-height: fit-content;
      margin: 0;
    }
  }
}
