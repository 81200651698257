@import url(../../../../assets/styles/_global.scss);

.viewOrderContainer {
  .viewOrderAction {
    width: calc(100% - 250px);
    margin: 0px 0 1.5rem;
    margin-inline-start: auto;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;

    svg {
      vertical-align: middle;
    }

    .routes {
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #202223;

      span {
        cursor: pointer;
      }
    }

    .rightSide {
      display: flex;
      align-items: center;
      gap: 10px;

      svg {
        vertical-align: middle;
      }
    }
  }

  .content {
    width: calc(100% - 295px);
    margin: 0 15px 1.875rem;
    margin-inline-start: auto;
    display: flex;
    gap: 20px;

    .container {
      padding: 10px 20px;
      background-color: white;
    }

    .header {
      background-color: #eeeded;
      display: flex;
      align-items: center;
      padding: 10px;
      border-radius: 4px;
      gap: 10px;

      svg {
        vertical-align: middle;
      }
    }

    & > div:last-child {
      width: 35%;
      height: max-content;
      margin-top: 2rem;
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: #ffffff;

      .customerDetailsHeader {
        display: flex;
        padding: 1.5rem;
        gap: 2rem;

        svg {
          vertical-align: text-bottom;
        }
      }

      hr {
        border-color: #ecf0f4;
        border-style: solid;
        border-width: 0.0625rem;
        width: 100%;
      }
    }

    & > div:first-of-type {
      width: 65%;
      margin-top: 1.875rem;

      img {
        width: 140px;
        height: 140px;
        border: 1.4px solid #17171742;
        border-radius: 5px;
      }

      .paymentContainer {
        margin-top: 1.875rem;

        .container {
          display: flex;
          flex-direction: column;
          width: 100%;

          & > div {
            line-height: 3rem;
            gap: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
              font-weight: 400;
              font-size: 20px;
              line-height: 20px;
              color: #344767;
            }

            .data {
              font-weight: 700;
              font-size: 18px;
              color: #344767;
            }
          }
        }
      }

      .customerPaymentContainer {
        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .data {
            padding: 0 10px;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #1a73e7;
          }
        }

        .container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;

          & > div {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              color: #344767;
            }

            .data {
              font-weight: 700;
              font-size: 16px;
              line-height: 20px;
              color: #344767;
            }
          }
        }
      }

      .statusContainer {
        margin-top: 1.875rem;
      }
    }
  }
}
