//Colors
$primaryColor: #344767;
$whiteColor: #ffffff;

//Fonts
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap");

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/metropolis/Metropolis-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/metropolis/Metropolis-ExtraBold.otf");
  font-weight: 900;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/metropolis/Metropolis-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/metropolis/Metropolis-Medium.otf");
  font-weight: 500;
}

//Media Queries
@mixin media($breakpoint) {
  @if $breakpoint ==phone-sm {
    @media only screen and (max-width: 320px) {
      @content;
    }
  }

  @if $breakpoint ==phone-md {
    @media only screen and (max-width: 480px) {
      @content;
    }
  }

  @if $breakpoint ==phone-lg {
    @media only screen and (max-width: 576px) {
      @content;
    }
  }

  @if $breakpoint ==tablet-sm {
    @media only screen and (max-width: 798px) {
      @content;
    }
  }

  @if $breakpoint ==tablet-md {
    @media only screen and (max-width: 900px) {
      @content;
    }
  }

  @if $breakpoint ==tablet-lg {
    @media only screen and (max-width: 1024px) {
      @content;
    }
  }

  @if $breakpoint ==desktop-sm {
    @media only screen and (max-width: 1200px) {
      @content;
    }
  }

  @if $breakpoint ==desktop-md {
    @media only screen and (max-width: 1366px) {
      @content;
    }
  }

  @if $breakpoint ==desktop-lg {
    @media only screen and (max-width: 1680px) {
      @content;
    }
  }
}

// Global Styles

body {
  margin: 0;
  padding: 0;
  font-family: "Metropolis", sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f4f5f7;

  * {
    font-family: "Metropolis", sans-serif !important;
  }
}

body.body-rtl {
  direction: rtl;
  text-align: right;
  font-family: 'Cairo', sans-serif;
  
  * {
    font-family: 'Cairo', sans-serif !important;
  }
}

*,
h2,
h3 {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

//scrollbar style
*::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

*::-webkit-scrollbar-track {
  background-color: $whiteColor;
}

*::-webkit-scrollbar-thumb {
  // background-color: $primaryColor;
  border-radius: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/********************************************************/

//Globals

//display flex
@mixin flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

/******************************/

.file-uploader-body {
  padding: 1.2rem;
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #344767;
  border-radius: 8px;
  cursor: pointer;

  .btn {
    all: unset;
    color: #1f5199;
    border-radius: 8px;
    background-color: #e5effd;
    padding: 0.6rem 0.6rem;
    margin-bottom: 0.5rem;
  }
}

.capitalize {
  text-transform: capitalize;
}
